import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Button, Dropdown, ButtonGroup, Badge } from '@themesberg/react-bootstrap';
import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import Table from '../../components/Tables/Table';
import { getLoads, multipleEliminationsLoads } from '../../services/api/loads';

import DeleteModel from '../../components/Modal/DeleteModel';

export default () => {
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [rowSelectionByPage, setRowSelectionByPage] = useState([]);
  const [totalRowSelection, setTotalRowSelection] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const columns = [
    {
      header: "#",
      footer: (props) => props.column.id,
      accessorKey: "item.load_id",
      cell: (info) => info.getValue(),
      sort: true
    },
    {
      header: "Origin",
      footer: (props) => props.column.id,
      accessorKey: "origin_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Destination",
      footer: (props) => props.column.id,
      accessorKey: "destination_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Pickup",
      footer: (props) => props.column.id,
      accessorKey: "item.load_pickup",
      cell: (info) => info.getValue(),
    },
    {
      header: "Miles",
      footer: (props) => props.column.id,
      accessorKey: "item.miles",
      cell: (info) => info.getValue(),
    },
    {
      header: "Equipment",
      footer: (props) => props.column.id,
      accessorKey: "item.load_equipment_type.equipment_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Status",
      footer: (props) => props.column.id,
      accessorKey: "item.load_status",
      cell: (info) => {
        const statusVariant =
          info.row.original.item.load_status === "active"
            ? "success"
            : "danger";

        return (
          <span className={`text-${statusVariant}`}>{info.getValue()}</span>
        );
      },
    },
  ];
  const [initialSorting, setInitialSorting] = useState([
    {
      desc: true,
      id: "item_load_id"
    }
  ]);

  const getPaginationList = useCallback((numberPage, numberTake, filter, sort) => {
    setLoading(true);
    setCurrentPage(numberPage)

    getLoads(numberPage, numberTake, filter, sort)
      .then((response) => {
        setList(response.data);
        setPagination({
          pageCount: response.pagination.pageCount,
          pageSize: response.pagination.take,
          pageIndex: response.pagination.page - 1,
          itemCount: response.pagination.itemCount,
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleRowSelectionChange = (rowSelection) => {
    let totalRowSelection = 0;

    if (!rowSelection.length) {
      setRowSelectionByPage({
        ...rowSelectionByPage,
        [currentPage]: []
      });

      let existRowSelection = false;

      Object.keys(rowSelectionByPage).forEach(index => {
        if (
          !existRowSelection &&
          Number(index) !== currentPage &&
          (rowSelectionByPage[index]?.length > 0)
        ) {
          existRowSelection = true;
        }
      });

      if (!existRowSelection) {
        setTotalRowSelection(0);
      } else {
        totalRowSelection = Object.keys(rowSelectionByPage)
          .filter(index => Number(index) !== currentPage)
          .reduce((total, index) => total + rowSelectionByPage[index].length, 0);

        setTotalRowSelection(totalRowSelection);
      }

      return;
    }

    setRowSelectionByPage({
      ...rowSelectionByPage,
      [currentPage]: rowSelection
    })

    totalRowSelection = Object.keys(rowSelection).length;

    totalRowSelection += Object.keys(rowSelectionByPage)
      .filter(index => Number(index) !== currentPage)
      .reduce((total, index) => total + rowSelectionByPage[index].length, 0);

    setTotalRowSelection(totalRowSelection);
  }

  const handleYes = () => {
    setIsLoadingDelete(true);

    const ids = [];
    Object.keys(rowSelectionByPage)
      .forEach(index => {
        rowSelectionByPage[index].forEach(selection => {
          ids.push(selection.original.item.load_id);
        })
      });

    multipleEliminationsLoads(ids)
      .then(() => {
        setShowDeleteModel(false);
        setRowSelectionByPage([]);
        setTotalRowSelection(0);
        getPaginationList(1, 10);
      })
      .finally(() => setIsLoadingDelete(false));
  }

  const handleSortingChange = (value) => {
    setInitialSorting(value);
    getPaginationList(
      1,
      10,
      null,
      value[0]
    );
  }

  useEffect(() => {
    getPaginationList(1, 10);
  }, [getPaginationList]);

  return (
    <>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <Row>
                    <Col xs={10}>
                      <h3>My Shipments</h3>
                    </Col>
                    <Col
                      xs={2}
                      className="text-right"
                    >
                      {totalRowSelection > 0 && <Button
                        variant="danger"
                        onClick={() => setShowDeleteModel(true)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                        <Badge bg="primary" className="me-2">{totalRowSelection}</Badge>
                        Delete
                      </Button>}
                    </Col>
                  </Row>
                  {loading ? (
                    <h1>Loading...</h1>
                  ) : (
                    <Table
                      columns={columns}
                      data={list}
                      pagination={pagination}
                      handlePagination={getPaginationList}
                      showIndeterminateCheckbox={true}
                      handleRowSelectionChange={handleRowSelectionChange}
                      rowsSelections={rowSelectionByPage[currentPage]}
                      totalRowSelection={totalRowSelection}
                      handleSortingChange={handleSortingChange}
                      initialSorting={initialSorting}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <DeleteModel
        show={showDeleteModel}
        handleClose={setShowDeleteModel}
        handleYes={handleYes}
        isLoading={isLoadingDelete}
      />
    </>
  );
};
